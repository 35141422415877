module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-fontawesome-virtual-c8b548ae1b/0/cache/gatsby-plugin-fontawesome-npm-1.3.1-b9b55fba6d-36b4b615cf.zip/node_modules/gatsby-plugin-fontawesome/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-dbe1c5c893/0/cache/gatsby-plugin-manifest-npm-5.14.0-8a89b421cf-9663fdaca2.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"John Goodliff | Software Developer","description":"Portfolio site for John Goodliff, a software developer based in Edmonton, Alberta.","short_name":"John Goodliff","start_url":"/","background_color":"#131313","theme_color":"#2DD4BF","display":"standalone","icon":"/home/runner/work/jerboa88.github.io/jerboa88.github.io/src/images/icon.svg","screenshots":[{"src":"images/og/index.webp","sizes":"2400x1260","type":"image/webp"}],"crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"80958ea6cecabc9e5b4a04a12097cc3e"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-offline-virtual-bf1f417d7f/0/cache/gatsby-plugin-offline-npm-6.14.0-87805d241e-4804d282fe.zip/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-remark-autolink-headers-virtual-0cd4140075/0/cache/gatsby-remark-autolink-headers-npm-6.14.0-1f93292f8e-9bd79d5209.zip/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"isIconAfterHeader":true,"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-881de8d42f/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
